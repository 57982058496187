const productData = [
  {
    index: 0,
    img: "/assets/img/vardagsrum/vardagsrum-1.webp",
    tag: "Living",
    category: "living-room",
    col: "4",
  },
  {
    index: 1,
    img: "/assets/img/vardagsrum/vardagsrum-2.webp",
    tag: "Living",
    category: "living-room",
    col: "4",
  },
  {
    index: 2,
    img: "/assets/img/vardagsrum/vardagsrum-3.webp",
    tag: "Living",
    category: "living-room",
    col: "4",
  },
  {
    index: 3,
    img: "/assets/img/vardagsrum/vardagsrum-4.webp",
    tag: "Living",
    category: "living-room",
    col: "4",
  },
  {
    index: 4,
    img: "/assets/img/vardagsrum/vardagsrum-5.webp",
    tag: "Living",
    category: "living-room",
    col: "4",
  },
  {
    index: 5,
    img: "/assets/img/vardagsrum/vardagsrum-6.webp",
    tag: "Living",
    category: "living-room",
    col: "4",
  },

  {
    index: 6,
    img: "/assets/img/sovrum/sovrum-1.webp",
    tag: "Bedroom",
    category: "bedroom",
    col: "4",
  },
  {
    index: 7,
    img: "/assets/img/sovrum/sovrum-2.webp",
    tag: "Bedroom",
    category: "bedroom",
    col: "4",
  },
  {
    index: 8,
    img: "/assets/img/sovrum/sovrum-3.webp",
    tag: "Bedroom",
    category: "bedroom",
    col: "4",
  },
  {
    index: 9,
    img: "/assets/img/sovrum/sovrum-4.webp",
    tag: "Bedroom",
    category: "bedroom",
    col: "4",
  },
  {
    index: 10,
    img: "/assets/img/sovrum/sovrum-5.webp",
    tag: "Bedroom",
    category: "bedroom",
    col: "4",
  },
  {
    index: 11,
    img: "/assets/img/sovrum/sovrum-6.webp",
    tag: "Bedroom",
    category: "bedroom",
    col: "4",
  },
  
  {
    index: 12,
    img: "/assets/img/badrum/badrum-1.webp",
    tag: "Bathroom",
    category: "bathroom",
    col: "4",
  },
  {
    index: 13,
    img: "/assets/img/badrum/badrum-2.webp",
    tag: "Bathroom",
    category: "bathroom",
    col: "4",
  },
  {
    index: 14,
    img: "/assets/img/badrum/badrum-3.webp",
    tag: "Bathroom",
    category: "bathroom",
    col: "4",
  },
  {
    index: 15,
    img: "/assets/img/badrum/badrum-4.webp",
    tag: "Bathroom",
    category: "bathroom",
    col: "4",
  },
  {
    index: 16,
    img: "/assets/img/badrum/badrum-5.webp",
    tag: "Bathroom",
    category: "bathroom",
    col: "4",
  },
  {
    index: 17,
    img: "/assets/img/badrum/badrum-6.webp",
    tag: "Bathroom",
    category: "bathroom",
    col: "4",
  },

  {
    index: 18,
    img: "/assets/img/garage/garage-1.webp",
    tag: "Garage",
    category: "garage",
    col: "4",
  },
  {
    index: 19,
    img: "/assets/img/garage/garage-2.webp",
    tag: "Garage",
    category: "garage",
    col: "4",
  },
  {
    index: 20,
    img: "/assets/img/garage/garage-3.webp",
    tag: "Garage",
    category: "garage",
    col: "4",
  },
  {
    index: 21,
    img: "/assets/img/garage/garage-4.webp",
    tag: "Garage",
    category: "garage",
    col: "4",
  },
  {
    index: 22,
    img: "/assets/img/garage/garage-5.webp",
    tag: "Garage",
    category: "garage",
    col: "4",
  },
  {
    index: 23,
    img: "/assets/img/garage/garage-6.webp",
    tag: "Garage",
    category: "garage",
    col: "4",
  },


  {
    index: 24,
    img: "/assets/img/others/extra-1.webp",
    tag: "Other",
    category: "others",
    col: "4",
  },
  {
    index: 25,
    img: "/assets/img/others/extra-2.webp",
    tag: "Other",
    category: "others",
    col: "4",
  },
  {
    index: 26,
    img: "/assets/img/others/extra-3.webp",
    tag: "Other",
    category: "others",
    col: "4",
  },
  {
    index: 27,
    img: "/assets/img/others/extra-4.webp",
    tag: "Other",
    category: "others",
    col: "4",
  },
  {
    index: 28,
    img: "/assets/img/others/extra-5.webp",
    tag: "Other",
    category: "others",
    col: "4",
  },
];

export default productData;
