import React, { useRef } from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import slider from '../../data/HomeSlider.json'
import { useTranslation } from 'react-i18next'

const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
  ],
}

const HomeSlider = () => {
  const sliderRef = useRef(null)
  const { t } = useTranslation()
  return (
    <>
      <div className="slider-home-three">
        <div className="tp-slider-area-3 p-relative">
          <Slider ref={sliderRef} {...settings} className="tp-silder-acive-3">
            {slider?.HomeSlider.map((item) => (
              
              <div key={item.id}>
                <div
                  className="tp-slider-height-3"
                  style={{
                    backgroundImage: `url(${item.img})`,
                    position: 'relative',
                  }}
                >
                     <div className="overlay"></div>
                  <div className="tp-slider-text white-box">
                    <span className="text-white">{t('heroDescription')}</span>
                    <h3 className="tp-slider-title tp-sl-lg-text mt-40 mb-55">
                      {t('heroTitleOne')}
                      <br /> {t('heroTitleTwo')}
                    </h3>

                    <div className="mt-45 mb-30">
                    <Link to="/service" className="tp-btn-white">
                      {t("heroGetStarted")}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </Link>
                  </div>
                  
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="slider-arrow-3 d-flex justify-content-between align-items-center">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              type="button"
              className="slick-prev slick-arrow"
              aria-label="previous"
            >
              <span>
                <i className="fal fa-angle-left"></i>
              </span>
            </button>
            <button
              onClick={() => sliderRef.current?.slickNext()}
              type="button"
              className="slick-next slick-arrow"
              aria-label="next"
            >
              <span>
                <i className="fal fa-angle-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeSlider
