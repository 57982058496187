import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TopFooter = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- footer top --> */}
          <div className="tp-footer__top pt-90 pb-60">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h3 className="tp-footer__top-title"> {t("topFooterTitle")}</h3>
                </div>
                <div className="col-lg-7">
                  <div className="tp-insta d-flex justify-content-lg-end">
                    <Link to="/contact" className="tp-btn-border white-bg">
                      {t("buttonLabel2")}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!-- line  --> */}
              <div className="tp-border-line d-block">
                <hr />
              </div>
              {/* <!-- line  --> */}
            </div>
          </div>

          {/* <!-- footer top end  --> */}
        </div>
      </div>
    </>
  );
};

export default TopFooter;
