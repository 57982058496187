import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import LanguageSwitcher from '../LanguageSwitcher'

import { useTranslation } from 'react-i18next'

const NavMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { t } = useTranslation()
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="assets/img/logo/logo-black.png" alt="company logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileMenu}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              isMobileMenuOpen ? 'show' : ''
            }`}
            id="navbarNav"
          >
            <ul
              className={`navbar-nav ms-auto my-lg-0 ${
                isMobileMenuOpen ? 'mobile-menu' : ''
              }`}
            >
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? 'nav-link active-nav-item' : 'nav-link'
                  }
                >
                  {t('navHome')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/service"
                  className={({ isActive }) =>
                    isActive ? 'nav-link active-nav-item' : 'nav-link'
                  }
                >
                  {t('navServices')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? 'nav-link active-nav-item' : 'nav-link'
                  }
                >
                  {t('navAboutUs')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? 'nav-link active-nav-item' : 'nav-link'
                  }
                >
                  {t('navContactUs')}
                </NavLink>
              </li>
            </ul>

            <div className="ml-auto d-lg-none">
              <LanguageSwitcher />
            </div>
          </div>

          <div className="ml-auto d-none d-lg-block">
            <LanguageSwitcher />
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavMenu
