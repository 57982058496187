import React from "react";

const Footer = () => {
  return (
    <div className="tp-footer__area black-bg">
      <div className="tp-footer">
        <footer className="black-bg text-center text-white">
          <div className="container p-4 pb-0">
            <div className="footer-top mb-10">
              {/* footer logo */}
              <div className="footer-logo">
                <a
                  href="/"
                  aria-label="Company logo that redirects on click"
                  role="button"
                >
                  <img
                    src="/assets/img/logo/footer-logo.png"
                    alt="footer-logo "
                  />
                </a>
              </div>

              {/* middle line */}
              <div className="line"></div>
              {/* footer text */}
              <section className="p-10">
                <a
                  className="btn btn-outline-light btn-floating"
                  href="https://www.instagram.com/epoxy.north/"
                  role="button"
                  aria-label="Link to our instagram page"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </section>
            </div>
          </div>

          <div className="text-center p-3">
            © {new Date().getFullYear()} {""}
            <a className="text-white" href="/">
              EpoxyNorth - All Rights Reserved.
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
