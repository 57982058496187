import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isActive, setIsActive] = useState(i18n.language === "ar");

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    setIsActive(!isActive);
    localStorage.setItem("language", i18n.language);
  };

  return (
    <div className="navbar">
      {/* Other navbar content */}
      <button
        className={`language-button ${isActive ? "active" : ""}`}
        onClick={toggleLanguage}
      >
        <span className="toggle-icon">
          {isActive ? "AR" : "EN"} {/* Add your flag icons */}
        </span>
        {isActive ? "🇦🇪" : "🇬🇧"}
      </button>
    </div>
  );
}

export default LanguageSwitcher;
