import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "@formspree/react";

const ContactInfo = () => {
  const [state, handleSubmit] = useForm("mbjvqjrn");
  const [submitted, setSubmitted] = useState(false);
  const FORM_ENDPOINT = "https://formspree.io/f/mbjvqjrn";
  const { t } = useTranslation();

  window.onbeforeunload = () => {
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }
  };

  if (state.succeeded) {
    setTimeout(() => {
      setSubmitted(true);
    }, 3000);
    return (
      <div className="tp-product-area pl-30 pr-30 pt-50 pb-50">
        <div className="container-fluid p-0">
          <div className="tp-product-header mb-30">
            <div className="row align-items-center g-0">
              <div className="col-lg-12 col-12">
                <div className="tp-section-wrapper text-center pb-20">
                  <div
                    id="form-message-success"
                    className="mb-4 w-100 text-center"
                  >
                    <h2 className="tp-pt-size">{t("contactSuccessMsg")}</h2>
                    <br />
                    <img
                      src="assets/img/success.png"
                      alt="message sent successfully icon"
                      width={75}
                      height={75}
                    />
                  </div>

                  <div className="mt-45 mb-30">
                    <Link to="/" className="tp-btn">
                      {t("contactBackHome")}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!submitted && (
        <div className="tp-product-area pl-30 pr-30 pt-50 pb-50">
          <div className="container-fluid p-0">
            <div className="tp-product-header mb-30">
              <div className="row align-items-center g-0">
                <div className="col-lg-12 col-12">
                  <div className="tp-section-wrapper text-center pb-20">
                    <span className="tp-section-subtitle mb-25">
                      {t("contactSubtitle")}
                    </span>
                    <h2 className="tp-pt-size">{t("contactSectiontitle")}</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12">
                <div className="wrapper">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 mb-5">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="dbox w-100 text-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-map-marker"></span>
                            </div>
                            <div className="icon-text">
                              <p className="pt-10">
                                <span className="contact-label">
                                  {t("contactAddress")}
                                </span>
                                <br />
                                <a
                                  href="http://maps.google.com/?q=Studentstaden 13, 75233 Uppsala"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Studentstaden 13, 
                                  75233 Uppsala
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 text-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-phone"></span>
                            </div>
                            <div className="icon-text">
                              <p className="pt-10">
                                <span className="contact-label">
                                  {t("contactPhone")}
                                </span>
                                <br />
                                <a
                                  href="tel:+46709322206"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  (+46) 0709322206{" "}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 text-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-paper-plane"></span>
                            </div>
                            <div className="icon-text">
                              <p className="pt-10">
                                <span className="contact-label">
                                  {t("contactEmail")}
                                </span>
                                <br />
                                <a
                                  href="mailto:contact@epoxynorth.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  contact@epoxynorth.com
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="contact-wrap">
                        <div className="mb-4 w-100 text-center">
                          <span className="tp-section-subtitle mt-25">
                            {t("contactFormSubtitle")}
                          </span>
                        </div>
                        <form
                          action={FORM_ENDPOINT}
                          onSubmit={handleSubmit}
                          method="post"
                          id="contactForm"
                          className="contactForm"
                        >
                          <div className="row input-wrapper">
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  placeholder=" "
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  id="name"
                                />
                                <label htmlFor="name">{t("contactName")}</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  placeholder=" "
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  id="email"
                                />
                                <label htmlFor="email">
                                  {t("contactEmail")}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  placeholder=" "
                                  type="tel"
                                  className="form-control"
                                  name="phone"
                                  id="phone"
                                />
                                <label htmlFor="phone">
                                  {t("contactPhone")}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea
                                  placeholder=" "
                                  name="message"
                                  className="form-control"
                                  id="message"
                                  cols="30"
                                  rows="8"
                                ></textarea>
                                <label htmlFor="message">
                                  {t("contactMessage")}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <button type="submit" className="tp-btn-border">
                                  {t("contactButtonLabel")}
                                  <span>
                                    <svg
                                      width="22"
                                      height="8"
                                      viewBox="0 0 22 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    <svg
                                      width="22"
                                      height="8"
                                      viewBox="0 0 22 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactInfo;
