import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
export default function ServiceImageLoad({ src }) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      if (src) {
        setLoaded(true)
      }
    }
    img.src = src
    setLoaded(false)

    return () => {
      img.onload = null
    }
  }, [src])

  return (
    <>
      {!loaded ? (
        <div className="w-100 h-100 lazy-image"></div>
      ) : (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opactiy: 0 }}
          className="w-100 h-100 lazy-image-wrapper"
        >
          <img
            className="w-100 h-100 lazy-image"
            style={{ borderRadius: '5px' }}
            src={src}
            alt="portfolio gallery images"
          />
        </motion.div>
      )}
    </>
  )
}
