import { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import productData from '../../../data/productData'

// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import ImagePopup from '../../common/Modals/ImagePopup'

const ServiceGallery = () => {
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState('living-room')
  const [imageData, setImageData] = useState('')

  // categories
  const categories = useMemo(() => {
    return [...new Set(productData.map((item) => item.category))]
  }, [])

  const handleCategoryClick = (cate) => {
    setIsActive(cate)
  }

  // set fitered images
  useEffect(() => {
    const newImages = productData.filter((item) => item.category === isActive)

    setImageData(newImages)
  }, [isActive])

  return (
    <>
      <div className="tp-product-area pl-20 pr-20 pt-50 pb-20">
        <div className="container-fluid p-0">
          <div className="tp-product-header mb-20">
            <div className="row align-items-center g-0">
              <div className="col-lg-12 col-12">
                <div className="tp-section-wrapper text-center">
                  <span className="tp-section-subtitle mb-15">
                    {t('servicesSubtitle')}
                  </span>
                  <h2 className="tp-section-title mb-30">
                    {t('servicesSectiontitle')}
                  </h2>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="tp-pf-btn text-lg-end">
                  {categories.map((cate, i) => (
                    <button
                      key={i}
                      onClick={() => handleCategoryClick(cate)}
                      className={`${isActive === cate ? 'active' : ''}`}
                    >
                      {t(String(cate.split('-').join(''))).toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="tp-product-active active">
            <ImagePopup imageData={imageData} />
          </div>
        </div>
        <div className="text-center mt-60">
          <Link to="/contact" className="tp-btn-border mb-40">
            {t('buttonLabel2')}
            <span>
              <svg
                width="22"
                height="8"
                viewBox="0 0 22 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                width="22"
                height="8"
                viewBox="0 0 22 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </Link>
        </div>
        <div className="tp-border-line d-block">
          <hr />
        </div>
      </div>
    </>
  )
}

export default ServiceGallery
