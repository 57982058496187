import React from "react";
import Footer from "../../common/Footer/Footer";
import ServiceBanner from "./ServiceBanner";
import ServiceGallery from "./ServiceGallery";
import TopFooter from "../../common/TopFooter/TopFooter";
import ServiceColors from "./ServiceColors";
import NavMenu from "../../common/NavMenu/NavMenu";

const Service = () => {
  return (
    <>
      <NavMenu />
      <ServiceBanner />
      <ServiceGallery />
      <ServiceColors />
      <TopFooter />
      <Footer />
    </>
  );
};

export default Service;
