import React from "react";
import Footer from "../../common/Footer/Footer";
import ContactInfo from "./ContactInfo";
import ContactUsBanner from "./ContactUsBanner";
import NavMenu from "../../common/NavMenu/NavMenu";

const ContactUs = () => {
  return (
    <>
      <NavMenu />
      <ContactUsBanner />
      <ContactInfo />
      <Footer />
    </>
  );
};

export default ContactUs;
