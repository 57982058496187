import React from "react";

import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";

import Footer from "../../common/Footer/Footer";
import TopFooter from "../../common/TopFooter/TopFooter";
import NavMenu from "../../common/NavMenu/NavMenu";

const About = () => {
  return (
    <>
      <NavMenu />
      <AboutBanner />
      <AboutCompany />
      <TopFooter />
      <Footer />
    </>
  );
};

export default About;
