import React from "react";
import Footer from "../common/Footer/Footer";
import TopAbout from "./TopAbout";
import HomeSlider from "./HomeSlider";
import TopFooter from "../common/TopFooter/TopFooter";
import NavMenu from "../common/NavMenu/NavMenu";

const Home = () => {
  return (
    <>
      <NavMenu />
      <HomeSlider />
      <TopAbout />
      <TopFooter />
      <Footer />
    </>
  );
};

export default Home;
