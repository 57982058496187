import React from "react";
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from "../components/BackToTop";
import ContextProvider from "../components/context/ContextProvider";
import Home from "../components/Home/Home";
import About from "../components/Pages/About/About";
import ContactUs from "../components/Pages/ContactUs/ContactUs";
import Service from "../components/Pages/Service/Service";

const AppNavigation = () => {
  return (
    <ContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;
