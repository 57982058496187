import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.min.js';
// internal
import ScrollTop from "./components/common/ScrollTop";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import App from "./App";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <BrowserRouter>
    <ScrollTop />
    {/* <React.StrictMode> */}
    <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
