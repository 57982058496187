import React from 'react'
import { useTranslation } from 'react-i18next'

const ServiceColors = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="tp-product-area pl-30 pr-30 pt-40 pb-50">
        <div className="container-fluid p-0">
          <div className="tp-product-header mb-50">
            <div className="row align-items-center g-0">
              <div className="col-lg-12 col-12">
                <div className="tp-section-wrapper text-center pb-20">
                  <span className="tp-section-subtitle mb-15">
                    {t('servicesSubtitleColors')}
                  </span>
                  <h2 className="tp-section-title">
                    {t('servicesSectiontitleColors')}
                  </h2>
                </div>
              </div>
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                <div className="mt-10 d-flex justify-content-center">
                  <img
                    src="assets/img/others/colors-new.webp"
                    alt="DIFFERENT COLORS TO CHOOSE FROM"
                    className="rounded img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-border-line d-block">
          <hr />
        </div>
      </div>
    </>
  )
}

export default ServiceColors
