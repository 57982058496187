import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutBanner = () => {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay about_banner"
        style={{
          backgroundImage: `url("assets/img/about/about-2.webp")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title">{t("aboutBannerTitle")}</h3>
                <div className="breadcrumb__list">
                  <span>
                    <Link to="/"> {t("bannerLink")}</Link>
                  </span>
                  <span className="dvdr">:</span>
                  <span>{t("aboutBannerSubtitle")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
