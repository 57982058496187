import React from 'react'

import LightGallery from 'lightgallery/react'
// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'

import ServiceImageLoad from './ServiceImageLoad'

export default React.memo(function ImagePopup({ imageData }) {
  return (
    <LightGallery
      speed={100}
      mobileSettings={{
        controls: true,
        showCloseIcon: true,
        download: true,
      }}
      mode="lg-fade"
      className="service-imgs"
    >
      {[...imageData].map((item) => (
        <a key={item.uniqueId} href={item.img} className="gallery-item">
          <ServiceImageLoad src={item.img} />
        </a>
      ))}
    </LightGallery>
  )
})
