import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../src/data/english.json';
import arTranslation from '../src/data/arabic.json';

const language = localStorage.getItem('language');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
    },
    lng: language || "en", // default language
    fallbackLng: language || "en", // fallback language
    interpolation: {
      escapeValue: false, // react already escapes by default
    },
  });

export default i18n;